<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        If you have
        <number-value :value="volume" unit="\text{mL}" />
        of a
        <number-value :value="wtPct" unit="\%\,\text{(w/w)}" />
        HCl solution, determine the concentration of the solution in each of the units shown below.
        Note that this solution has a density of
        <latex-number :number="density.toFixed(2)" unit="\text{g/mL.}" />
      </p>

      <p class="mb-3">a) Concentration in ppm:</p>

      <calculation-input
        v-model="inputs.ppm"
        class="mb-5"
        append-text="$\text{ppm}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">b) Concentration in molarity:</p>

      <calculation-input
        v-model="inputs.molarity"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber';

export default {
  name: 'Question412',
  components: {
    CalculationInput,
    NumberValue,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        ppm: null,
        molarity: null,
      },
    };
  },
  computed: {
    volume() {
      return this.taskState.getValueBySymbol('volume').content;
    },
    wtPct() {
      return this.taskState.getValueBySymbol('wtPct').content;
    },
    density() {
      return 0.0051 * this.wtPct.toFloat() + 1;
    },
  },
};
</script>
